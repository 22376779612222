<template>
  <fetch-data :get="{ article: 'articles/single', attrs: { id } }">
    <template #default="{ article }">
      <MapMaker v-if="article.type === 'map'" :article="article" />
      <Article v-else :article="article" />
    </template>
    <template #loading>Loading article...</template>
    <template #failed>Failed...</template>
  </fetch-data>
</template>

<script>
import Article from './Article.vue';
import MapMaker from '../map/Test.vue';

export default {
  components: {
    Article,
    MapMaker,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
